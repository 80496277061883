body {
  background-color: #121212;
  height: 100svh;
}

* {
  box-sizing: border-box;
}

.App {
  height: 100svh;
}

button {
  border: none;
  outline: none;
}
a {
  text-decoration: none;
}
.btn {
  display: inline-flex;
  background: white;
  color: #121212;
  border-radius: 100px;
  padding: 5px 15px;
  align-items: center;
  justify-content: center;
}
.menu-home {
  width: 100%;
  height: 100svh;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.duy-yen {
  width: 100%;
  height: 100svh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.duy-yen .wrapper {
  width: calc(100% - 30px);
  height: calc(100% - 30px);
  padding: 15px;
  border-radius: 15px;
  overflow: hidden;
  background-color: white;
  text-align: center;
  position: relative;
}

.duy-yen .wrapper .btn {
  position: absolute;
  bottom: 15px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #121212;
  color: white;
  line-height: 0;
  height: 36px;
}